import React, { createContext, useContext, useState } from "react";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);

  // Função para comparar variações
  const areVariationsEqual = (var1, var2) =>
    var1?.weight === var2?.weight && var1?.flavor === var2?.flavor;

  const logCartState = (message) => {
    console.log(message, cartItems);
  };

  // Adicionar ao carrinho
  const addToCart = (product) => {
    setCartItems((prevItems) => {
      const existingProduct = prevItems.find(
        (item) =>
          item.id === product.id &&
          areVariationsEqual(item.variation, product.variation)
      );

      const updatedCart = existingProduct
        ? prevItems.map((item) =>
            item.id === product.id &&
            areVariationsEqual(item.variation, product.variation)
              ? { ...item, quantity: item.quantity + 1 }
              : item
          )
        : [...prevItems, { ...product, quantity: 1 }];

      logCartState("Carrinho atualizado após adicionar:");
      return updatedCart;
    });
  };

  // Remover do carrinho
  const removeFromCart = (productId, variation) => {
    setCartItems((prevItems) => {
      const updatedItems = prevItems.filter((item) => {
        const isSameProduct = item.id === productId;
        const isSameVariation = JSON.stringify(item.variation) === JSON.stringify(variation);
        return !(isSameProduct && isSameVariation); // Remove somente se ambos forem iguais
      });
      console.log("Carrinho atualizado no estado:", updatedItems);
      return updatedItems;
    });
  };
  

  // Atualizar quantidade
  const updateQuantity = (productId, quantity, variation) => {
    if (quantity < 1) {
      removeFromCart(productId, variation);
      return;
    }
    setCartItems((prevItems) =>
      prevItems.map((item) =>
        item.id === productId &&
        areVariationsEqual(item.variation, variation)
          ? { ...item, quantity }
          : item
      )
    );
    console.log("Quantidade atualizada:", { productId, quantity, variation });
    logCartState("Carrinho atualizado após alteração de quantidade:");
  };

  return (
    <CartContext.Provider
      value={{ cartItems, addToCart, removeFromCart, updateQuantity }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => useContext(CartContext);
