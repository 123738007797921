import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; // Adicionando o hook useNavigate

const Profile = ({ setClientData }) => {
  const [cpf, setCpf] = useState('');
  const [phone, setPhone] = useState('');
  const [orders, setOrders] = useState([]); // Histórico de pedidos
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(true); // Novo estado para controlar o modal

  const location = useLocation(); // Usando o hook useLocation para acessar a URL atual
  const navigate = useNavigate(); // Hook para navegação

  // Efeito para verificar se o cliente já está logado
  useEffect(() => {
    const storedClientData = localStorage.getItem('clientData');
    if (storedClientData) {
      const { cpf, phone } = JSON.parse(storedClientData);
      setCpf(cpf);
      setPhone(phone);
      setIsLoggedIn(true); // Cliente já está logado
    }
  }, []);

  // Função para realizar o login
  const handleLogin = async () => {
    if (!cpf || !phone) {
      setError('Por favor, preencha todos os campos.');
      return;
    }

    const newClientData = { cpf, phone };

    if (setClientData) {
      setClientData(newClientData); // Salva os dados do cliente no estado global
    } else {
      console.error("setClientData não está definido");
    }

    // Salva os dados no localStorage
    localStorage.setItem('clientData', JSON.stringify(newClientData));

    // Simulação de login
    try {
      const response = await fetch(`https://mimimiracoes.com.br/getClientOrders.php?cpf=${cpf}&telefone=${phone}`);
      const data = await response.json();

      if (data.status === 'success') {
        setIsLoggedIn(true); // Login bem-sucedido
        setOrders(data.orders);
        if (data.message) {
          setSuccessMessage(data.message); // Exibe a mensagem de sucesso
        }

        // Redireciona para a página de perfil após login bem-sucedido
        navigate('/profile'); // Redireciona o usuário para a página de perfil
      } else {
        setError(data.message || 'Erro ao buscar dados do cliente.');
      }
    } catch (error) {
      setError('Erro ao conectar com o servidor.');
    }
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setShowWelcomeModal(false); // Fecha o modal
  };

  // Verifica se a rota atual é a página de perfil
  const isProfilePage = location.pathname === '/profile';

  // O modal de boas-vindas só será exibido na página de perfil
  useEffect(() => {
    if (!isProfilePage) {
      setShowWelcomeModal(false); // Se não for a página de perfil, fecha o modal
    }
  }, [isProfilePage]);

  return (
    <div>
      {showWelcomeModal && isLoggedIn && isProfilePage && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-20">
          <div className="bg-white p-6 rounded shadow-md w-96">
            <h2 className="text-lg font-bold mb-4">Bem-vindo, {cpf}</h2>
            <button
              onClick={handleCloseModal}
              className="absolute top-2 right-2 text-xl font-bold text-red-500"
            >
              ×
            </button>
          </div>
        </div>
      )}

      <div className="bg-white p-6 rounded shadow-md w-96">
        <h2 className="text-lg font-bold mb-4">Perfil</h2>

        {error && <p className="text-red-500 mb-2">{error}</p>}
        {successMessage && <p className="text-green-500 mb-2">{successMessage}</p>}

        {!isLoggedIn ? (
          <>
            <div className="mb-4">
              <label className="block mb-2">CPF:</label>
              <input
                type="text"
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                className="border rounded w-full p-2"
                placeholder="Digite seu CPF"
              />
            </div>

            <div className="mb-4">
              <label className="block mb-2">Telefone:</label>
              <input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                className="border rounded w-full p-2"
                placeholder="Digite seu telefone"
              />
            </div>

            <div className="flex justify-end">
              <button onClick={handleLogin} className="bg-blue-500 text-white py-2 px-4 rounded">
                Login
              </button>
            </div>
          </>
        ) : (
          <>
            <h3 className="text-xl font-semibold">Bem-vindo, {cpf}</h3>
            <div className="mt-4">
              <h4 className="text-lg">Histórico de Pedidos:</h4>
              <ul>
                {orders.length > 0 ? (
                  orders.map((order, index) => (
                    <li key={index}>
                      Pedido #{order.id}: {order.status} em {order.date}
                    </li>
                  ))
                ) : (
                  <p>Você não tem pedidos ainda.</p>
                )}
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Profile;
