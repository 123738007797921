import React, { useState } from "react";

const UploadPage = () => {
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      alert("Por favor, selecione um arquivo!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch("https://mimimiracoes.com.br/importar_produtos.php", {
        method: "POST",
        body: formData,
      });

      const result = await response.text();
      setMessage(result);
    } catch (error) {
      console.error("Erro ao enviar o arquivo:", error);
      setMessage("Erro ao enviar o arquivo.");
    }
  };

  return (
    <div>
      <h1>Upload de Planilha</h1>
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpload}>Enviar</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default UploadPage;
