import React, { useEffect, useState } from "react";

const HorizontalMenu = ({ categories }) => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50); // Define a classe 'scrolled' com base na rolagem
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleCategoryClick = (category) => {
    // Tenta localizar a seção da categoria pelo id
    const categorySection = document.getElementById(category);

    if (categorySection) {
      const offsetTop = categorySection.offsetTop - 80; // Ajusta a rolagem para exibir o título
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    } else {
      console.error(`Seção de categoria com id "${category}" não encontrada.`);
    }
  };

  return (
    <div
      className={`sticky ${isScrolled ? "scrolled" : ""}`}
      style={{ zIndex: 1000 }}
    >
      <div className="flex overflow-x-auto scrollbar-hide space-x-4 p-4">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => handleCategoryClick(category)}
            className={`flex-shrink-0 px-4 py-2 text-sm rounded whitespace-nowrap ${
              isScrolled
                ? "text-gray-700 hover:text-red-500"
                : "text-gray-900 font-bold"
            }`}
          >
            {category}
          </button>
        ))}
      </div>
    </div>
  );
};

export default HorizontalMenu;
