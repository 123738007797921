import React, { useState, useEffect } from "react";
import { useCart } from "../contexts/CartContext";
import { Link } from "react-router-dom";
import HorizontalMenu from "./HorizontalMenu";
import ProductModal from "./ProductModal";

const Home = () => {
  const { addToCart } = useCart();
  const [productsByCategory, setProductsByCategory] = useState({});
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  

  useEffect(() => {
    fetch("https://mimimiracoes.com.br/getProducts.php")
      .then((response) => response.json())
      .then((data) => {
        const categorizedProducts = data.reduce((categories, product) => {
          if (
            !product ||
            !product.categoria || // Verifica se o produto tem categoria
            !product.nome // Verifica se o produto tem nome
          ) {
            console.warn("Produto inválido encontrado:", product);
            return categories;
          }

          if (!categories[product.categoria]) {
            categories[product.categoria] = [];
          }
          categories[product.categoria].push(product);
          return categories;
        }, {});
        setProductsByCategory(categorizedProducts);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao buscar produtos:", error);
        setLoading(false);
      });
  }, []);

  const handleOpenModal = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
    setShowModal(false);
  };

  if (loading) {
    return <p className="text-gray-500">Carregando produtos...</p>;
  }

  if (!productsByCategory || Object.keys(productsByCategory).length === 0) {
    return <p className="text-gray-500">Nenhum produto disponível no momento.</p>;
  }

  return (
    <>
      <HorizontalMenu
        categories={Object.keys(productsByCategory || {})}
        onCategoryClick={(category) => {
          const categorySection = document.getElementById(category);
          if (!categorySection) {
            console.warn(`Categoria "${category}" não encontrada no DOM.`);
            return;
          }
          const offsetTop = categorySection.offsetTop - 80;
          window.scrollTo({
            top: offsetTop,
            behavior: "smooth",
          });
        }}
      />

      <div className="p-4 mt-16">
        {Object.entries(productsByCategory || {}).map(([categoryName, products]) => (
          <div key={categoryName} id={categoryName} className="mb-8">
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-bold text-gray-800">{categoryName}</h2>
              <Link to={`/category/${categoryName}`}>
                <button className="text-blue-500 hover:underline text-sm">Ver todos</button>
              </Link>
            </div>
            <div className="flex overflow-x-auto scrollbar-hide space-x-4">
              {products.slice(0, 10).map((product) => {
                if (!product) return null; // Ignora produtos inválidos
                return (
                  <div
                    key={product.id}
                    className="p-4 border rounded shadow-md flex-shrink-0 w-64"
                  >
                    <img
                      src={product.imagem || "/images/placeholder.png"}
                      alt={product.nome || "Produto sem nome"}
                      className="w-full h-32 object-contain rounded"
                    />
                    <h3 className="text-sm font-semibold mt-2">{product.nome}</h3>
                    <p className="text-gray-500 text-xs">{product.descricao}</p>
                    <p className="text-green-500 font-bold mt-2">R$ {product.preco}</p>
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded mt-4 hover:bg-blue-600"
                      onClick={() => handleOpenModal(product)}
                    >
                      Ver produto
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>

      {showModal && selectedProduct && (
        <ProductModal
          product={selectedProduct}
          onClose={handleCloseModal}
          addToCart={addToCart}
        />
      )}
    </>
  );
};

export default Home;
