import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom"; // useLocation para controle de navegação
import { useCart } from "../contexts/CartContext";
import { Link } from 'react-router-dom';

const FooterNav = () => {
  const navigate = useNavigate();
  const { cartItems } = useCart();
  const [clientData, setClientData] = useState(null);

  const cartItemsCount = cartItems.reduce((total, item) => total + item.quantity, 0);
  const location = useLocation(); // hook useLocation para verificar a rota atual

  const handleProfileClick = () => {
    navigate("/profile"); // Redireciona para a página de perfil ao clicar
  };

  const handleNavigate = (path) => {
    navigate(path);
  };

  // Verifica se a página atual é a de Perfil
  const isProfilePage = location.pathname === "/profile";

  return (
    <div>
      <div className="fixed bottom-0 left-0 right-0 bg-white shadow-md p-4 flex justify-around z-10">
        <button onClick={() => handleNavigate("/")} className="flex flex-col items-center">
          🏠
          <span>Início</span>
        </button>

        <button onClick={() => handleNavigate("/search")} className="flex flex-col items-center">
          🔍
          <span>Buscar</span>
        </button>

        <button onClick={() => handleNavigate("/cart")} className="flex flex-col items-center relative">
          🛒
          <span>Carrinho</span>
          {cartItemsCount > 0 && (
            <span className="absolute top-0 right-0 bg-red-500 text-white rounded-full px-2 text-xs">
              {cartItemsCount}
            </span>
          )}
        </button>

        {/* Exibe o botão de login apenas na Home e não na página de perfil 
        {location.pathname !== "/profile" && (
          <button onClick={handleProfileClick} className="flex flex-col items-center">
            👤
            <span>Login</span>
          </button>
        )} */}
      </div>
    </div>
  );
};

export default FooterNav;
