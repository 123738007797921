import React from 'react';

// Importando a logo
import logo from '../images/logo.png'; // Verifique o caminho correto

const Header = () => {
  return (
    <header className="bg-white shadow-md p-4 flex items-center justify-start">
      {/* Logo e Texto ao lado */}
      <div className="flex items-center">
        <img src={logo} alt="Mimimi Rações" className="h-24 mr-6" /> {/* Logo maior */}
        
        {/* Texto informativo ao lado da logo */}
        <div className="text-left text-sm text-gray-500">
          <p className="font-medium text-gray-800">Pedido mínimo R$ 30,00</p>
          <p>Entrega hoje: 80-90 min</p>
        </div>
      </div>
    </header>
  );
};

export default Header;
