import React, { useEffect, useState } from 'react';
import { useCart } from '../contexts/CartContext';
import ProductModal from './ProductModal'; // Importe o seu modal de produto

const SearchResults = ({ searchTerm, setSearchTerm }) => {
  const { cartItems, addToCart } = useCart(); // Obtém os itens do carrinho e a função de adicionar produtos ao carrinho
  const [filteredProducts, setFilteredProducts] = useState([]); // Estado para armazenar os produtos filtrados
  const [loading, setLoading] = useState(false); // Estado para indicar que está carregando
  const [error, setError] = useState(null); // Estado para tratar possíveis erros
  const [debounceTimer, setDebounceTimer] = useState(null); // Estado para controlar o debounce
  const [modalOpen, setModalOpen] = useState(false); // Estado para controlar a exibição do modal
  const [selectedProduct, setSelectedProduct] = useState(null); // Estado para armazenar o produto selecionado

  // Função para adicionar ao carrinho
  const handleAddToCart = (product) => {
    addToCart(product); // Usa a função addToCart do contexto
  };

  // Função para abrir o modal com os detalhes do produto
  const openProductModal = (product) => {
    setSelectedProduct(product); // Armazena o produto selecionado
    setModalOpen(true); // Abre o modal
  };

  // Função para fechar o modal
  const closeProductModal = () => {
    setModalOpen(false); // Fecha o modal
    setSelectedProduct(null); // Limpa o produto selecionado
  };

  // UseEffect para fazer a requisição de produtos toda vez que o searchTerm mudar
  useEffect(() => {
    const fetchProducts = async () => {
      if (searchTerm.trim() === '') {
        setFilteredProducts([]); // Limpa os resultados se não houver termo de busca
        return;
      }

      setLoading(true); // Ativa o estado de carregamento
      setError(null); // Reseta erro anterior

      try {
        // Realiza a requisição para o backend (ajuste o URL conforme sua API)
        const response = await fetch(`https://mimimiracoes.com.br/getProducts.php?searchTerm=${searchTerm}`);
        const data = await response.json();

        if (!response.ok) {
          throw new Error('Erro ao buscar produtos.');
        }

        // Atualiza o estado com os produtos filtrados
        setFilteredProducts(data);
      } catch (err) {
        setError('Erro ao carregar os produtos.');
        console.error(err);
      } finally {
        setLoading(false); // Desativa o estado de carregamento
      }
    };

    // Aguarda a digitação ser concluída (debounce)
    if (debounceTimer) clearTimeout(debounceTimer); // Limpa o timer anterior
    const timer = setTimeout(fetchProducts, 500); // Aguarda 500ms após a última tecla digitada
    setDebounceTimer(timer);

    return () => clearTimeout(timer); // Limpa o timer ao desmontar o componente
  }, [searchTerm]); // Recarrega a busca toda vez que searchTerm mudar

  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold text-gray-800 mb-4">Resultados de Busca</h1>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)} // Atualiza o termo de busca
        placeholder="Buscar produtos..."
        className="border rounded w-full p-2 mb-4"
      />

      {loading && <p className="text-gray-500">Carregando...</p>} {/* Exibe mensagem de carregamento */}
      
      {error && <p className="text-red-500">{error}</p>} {/* Exibe erro se houver */}

      {filteredProducts.length > 0 ? (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
          {filteredProducts.map((product) => (
            <div key={product.id} className="border p-4 rounded shadow hover:shadow-lg">
              <img
                src={product.imagem || '/images/placeholder.png'} // Exibe imagem do produto
                alt={product.nome}
                className="w-full h-32 object-contain rounded"
              />
              <h3 className="text-lg font-bold text-gray-800">{product.nome}</h3>
              <p className="text-gray-600">R$ {product.preco}</p>
              <button
                onClick={() => openProductModal(product)} // Abre o modal com o produto
                className="mt-2 bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600"
              >
                Ver Detalhes
              </button>
             
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500">Nenhum produto encontrado.</p> // Caso não haja resultados
      )}

      {/* Modal para mostrar detalhes do produto */}
      {modalOpen && selectedProduct && (
        <ProductModal
          product={selectedProduct}
          onClose={closeProductModal} // Passa a função para fechar o modal
        />
      )}
    </div>
  );
};

export default SearchResults;
