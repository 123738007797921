import React, { useState } from "react";

const ProductModal = ({ product, onClose, addToCart }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleAddToCart = () => {
    if (!selectedOption) {
      alert("Por favor, selecione uma variação!");
      return;
    }

    // Adiciona o produto com a variação selecionada
    addToCart({
      ...product,
      variation: {
        weight: selectedOption.tamanho,
        flavor: selectedOption.sabor,
      },
    });
    onClose(); // Fecha o modal
  };

  if (!product) return null;

  // Função para combinar as variações
  const combineVariations = () => {
    let variations = [];
    if (product.variacoes) {
      try {
        // Garante que as variações sejam um objeto, mesmo se vierem como string
        const parsedVariations =
          typeof product.variacoes === "string"
            ? JSON.parse(product.variacoes)
            : product.variacoes;

        const { tamanhos = [], sabores = [] } = parsedVariations;

        // Gera combinações de tamanhos e sabores
        variations = sabores.flatMap((sabor) =>
          tamanhos.map((tamanho) => ({
            label: `Sabor ${sabor} ${tamanho}`,
            value: { sabor, tamanho },
          }))
        );
      } catch (error) {
        console.error("Erro ao decodificar variações:", error);
      }
    }
    return variations;
  };

  const combinedVariations = combineVariations();

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-4 rounded shadow-md w-96">
        <h2 className="text-lg font-bold">{product.nome}</h2>

        {/* Exibe a imagem do produto */}
        <img
          src={product.imagem || "/images/placeholder.png"} // Usa uma imagem padrão caso o produto não tenha imagem
          alt={product.nome}
          className="w-full h-64 object-contain rounded mb-4"
        />

        {/* Descrição do produto */}
        <p className="text-gray-500">
          {product.descricao
            ? product.descricao.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))
            : "Descrição não disponível."}
        </p>

        <p className="text-green-600 font-bold">R$ {product.preco}</p>

        {/* Exibição das variações combinadas */}
        {combinedVariations.length > 0 ? (
          <select
            value={selectedOption ? JSON.stringify(selectedOption) : ""}
            onChange={(e) =>
              setSelectedOption(JSON.parse(e.target.value)) // Atualiza o estado com a variação selecionada
            }
            className="w-full border p-2 rounded mt-4"
          >
            <option value="">Selecione uma variação</option>
            {combinedVariations.map((variation, index) => (
              <option key={index} value={JSON.stringify(variation.value)}>
                {variation.label}
              </option>
            ))}
          </select>
        ) : (
          <p className="text-gray-500 mt-4">Sem variações disponíveis.</p>
        )}

        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="bg-gray-300 px-4 py-2 rounded mr-2 hover:bg-gray-400"
          >
            Cancelar
          </button>
          <button
            onClick={handleAddToCart}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Adicionar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
