import React from 'react';

const OrderHistory = ({ orders }) => {
  return (
    <div className="p-4">
      <h1 className="text-2xl font-bold mb-4">Histórico de Pedidos</h1>
      {orders.length > 0 ? (
        orders.map((order, index) => (
          <div key={index} className="p-4 border rounded mb-4 shadow">
            <h2 className="text-lg font-bold">Pedido #{order.id}</h2>
            <p>Total: R$ {order.total.toFixed(2)}</p>
            <p>Data: {order.date}</p>
            <p>Status: {order.status}</p>
          </div>
        ))
      ) : (
        <p className="text-gray-500">Nenhum pedido encontrado.</p>
      )}
    </div>
  );
};

export default OrderHistory;
