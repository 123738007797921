import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useCart } from "../contexts/CartContext";
import ProductModal from "./ProductModal";

const CategoryPage = ({ productsByCategory }) => {
  const { categoryName } = useParams();
  const navigate = useNavigate();
  const { addToCart } = useCart();
  const [showModal, setShowModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Função para abrir o modal de compra
  const handleOpenModal = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setSelectedProduct(null);
    setShowModal(false);
  };

  // Verifica se a categoria existe e se há produtos na categoria
  if (!productsByCategory || !productsByCategory[categoryName]) {
    return (
      <p className="text-center text-gray-500">
        Categoria não encontrada ou sem produtos.
      </p>
    );
  }

  return (
    <div className="p-4 flex flex-col min-h-screen">
      <button
        onClick={() => navigate("/")}
        className="bg-blue-500 text-white px-4 py-2 rounded mb-4 w-fit hover:bg-blue-600"
      >
        Voltar para a Home
      </button>

      <h1 className="text-2xl font-bold text-gray-800 mb-4">
        Produtos na Categoria: {categoryName}
      </h1>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {productsByCategory[categoryName].map((product) => {
          if (!product) return null; // Ignora produtos inválidos
          return (
            <div
              key={product.id}
              className="border rounded shadow-md p-4 flex flex-col justify-between"
            >
              <img
                src={product.imagem || "/images/placeholder.png"} // Garante que sempre haja uma imagem
                alt={product.nome || "Produto sem nome"}
                className="w-full h-32 object-contain rounded mb-4"
              />
              <div>
                <h2 className="text-lg font-bold text-gray-800">
                  {product.nome || "Produto sem nome"}
                </h2>
                <p className="text-green-600 font-semibold text-sm mt-2">
                  R$ {product.preco || "0.00"}
                </p>
              </div>
              <button
                onClick={() => handleOpenModal(product)}
                className="bg-green-500 text-white mt-4 py-2 px-4 rounded hover:bg-green-600 transition duration-200"
              >
                Comprar
              </button>
            </div>
          );
        })}
      </div>

      {showModal && selectedProduct && (
        <ProductModal
          product={selectedProduct}
          onClose={handleCloseModal}
          addToCart={addToCart}
        />
      )}
    </div>
  );
};

export default CategoryPage;
