import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";
import Header from "./components/Header";
import FooterNav from "./components/FooterNav";
import Home from "./components/Home";
import SearchResults from "./components/SearchResults";
import Cart from "./components/Cart";
import AdminLogin from "./components/AdminLogin";
import AdminPanel from "./components/AdminPanel";
import Profile from "./components/Profile";
import OrderHistory from "./components/OrderHistory";
import CategoryPage from "./components/CategoryPage";
import { CartProvider } from "./contexts/CartContext";
import ThankYouPage from "./components/ThankYouPage";
import UploadPage from "./UploadPage";


const App = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [view, setView] = useState("home");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [productsByCategory, setProductsByCategory] = useState({});
  const [showProfile, setShowProfile] = useState(false);
  const [clientData, setClientData] = useState({ cpf: "", phone: "" });
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1); // Controla a página da API
  const [hasMore, setHasMore] = useState(true); // Verifica se há mais produtos para carregar
  const loadMoreRef = useRef(null); // Referência para o observer de carregamento infinito

  // Função de cache para evitar carregar dados repetidamente
  const getCachedProducts = () => {
    const cachedProducts = localStorage.getItem("products");
    return cachedProducts ? JSON.parse(cachedProducts) : null;
  };

  // Função para buscar produtos da API com paginação
  const fetchProducts = async () => {
    setLoading(true);
    setError(null);

    try {
      // Verifica o cache antes de fazer a requisição
      const cachedProducts = getCachedProducts();
      if (cachedProducts) {
        setProductsByCategory(cachedProducts);
        setLoading(false);
        return;
      }

      const response = await axios.get(`https://mimimiracoes.com.br/getProducts.php?page=${page}&limit=10`);
      if (!response.data) {
        throw new Error("Erro ao carregar os produtos.");
      }

      const data = response.data;
      const groupedByCategory = data.reduce((acc, product) => {
        if (!acc[product.categoria]) acc[product.categoria] = [];
        acc[product.categoria].push(product);
        return acc;
      }, {});

      localStorage.setItem("products", JSON.stringify(groupedByCategory)); // Armazena os produtos no cache
      setProductsByCategory(groupedByCategory);
      setHasMore(data.length > 0); // Se a resposta tiver produtos, continua a paginação
    } catch (error) {
      console.error("Erro ao carregar produtos:", error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Carrega produtos na primeira renderização ou quando a página muda
  useEffect(() => {
    fetchProducts();
  }, [page]);

  // Função para carregar mais produtos (scroll infinito)
  const loadMoreProducts = () => {
    if (hasMore && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  useEffect(() => {
    const storedClientData = localStorage.getItem('clientData');
    if (storedClientData) {
      setClientData(JSON.parse(storedClientData)); // Recupera os dados do cliente armazenados
    }
  }, []);

  // Observer para detectar quando o usuário chega ao final da lista e carregar mais
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadMoreProducts();
      }
    }, { threshold: 1.0 });

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => observer.disconnect();
  }, [hasMore]);

  const handleSetView = (newView) => {
    if (newView === "home") {
      setSearchTerm("");
    }
    setView(newView);
  };

  const handleClientLogin = (clientData) => {
    console.log("Login do cliente efetuado:", clientData);
    setClientData(clientData);
    setShowProfile(false);
    setIsLoggedIn(true); // Marca o cliente como logado
  };

  // Renderiza estado de carregamento ou erro
  if (loading) {
    return <p className="text-gray-500 text-center mt-8">Carregando produtos...</p>;
  }

  if (error) {
    return (
      <div className="text-center text-red-500 mt-8">
        <p>{error}</p>
      </div>
    );
  }

  // Renderiza o aplicativo principal
  return (
    <CartProvider>
      <Router>
        <Header
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          onProfileClick={() => setShowProfile(true)}
        />

        {showProfile && (
          <Profile
            clientData={clientData}
            setClientData={setClientData}
            onLogin={handleClientLogin}
            onClose={() => setShowProfile(false)}
          />
        )}

        <Routes>
          <Route
            path="/"
            element={
              <>
                <Home
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  productsByCategory={productsByCategory}
                />
              </>
            }
          />
          <Route path="/search" element={<SearchResults searchTerm={searchTerm} />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/orderHistory" element={<OrderHistory />} />
          <Route path="/profile" element={isLoggedIn ? <Profile /> : <Home />} />
          <Route path="/admin" element={isLoggedIn ? <AdminPanel /> : <AdminLogin />} />
          <Route path="/category/:categoryName" element={<CategoryPage productsByCategory={productsByCategory} />} />
          <Route path="/upload" element={<UploadPage />} />
        </Routes>

        <FooterNav onProfileClick={() => setShowProfile(true)} />

        {/* Referência para o carregamento de mais produtos */}
        <div ref={loadMoreRef} className="load-more">
          {loading && <p>Carregando mais...</p>}
        </div>
      </Router>
    </CartProvider>
  );
};

export default App;
