import React, { useState, useEffect } from "react";

const AdminPanel = () => {
  const [products, setProducts] = useState([]); // Lista de produtos
  const [newProduct, setNewProduct] = useState({
    name: "",
    price: "",
    category: "",
    active: 1,
  });
  const [editingProduct, setEditingProduct] = useState(null); // Produto em edição

  // Busca produtos no backend
  const fetchProducts = async () => {
    try {
      const response = await fetch("http://localhost:3001/api/products");
      const data = await response.json();
      setProducts(data);
    } catch (error) {
      console.error("Erro ao buscar produtos:", error);
    }
  };

  // Adiciona ou edita um produto
  const handleSaveProduct = async () => {
    const url = editingProduct
      ? `http://localhost:3001/api/products/${editingProduct.id}`
      : "http://localhost:3001/api/products";

    const method = editingProduct ? "PUT" : "POST";

    try {
      const response = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newProduct),
      });
      if (response.ok) {
        alert(`Produto ${editingProduct ? "atualizado" : "adicionado"} com sucesso!`);
        setNewProduct({ name: "", price: "", category: "", active: 1 });
        setEditingProduct(null);
        fetchProducts(); // Atualiza a lista
      } else {
        alert("Erro ao salvar produto.");
      }
    } catch (error) {
      console.error("Erro ao salvar produto:", error);
    }
  };

  // Remove um produto
  const handleDeleteProduct = async (id) => {
    try {
      const response = await fetch(`http://localhost:3001/api/products/${id}`, {
        method: "DELETE",
      });
      if (response.ok) {
        alert("Produto removido com sucesso!");
        fetchProducts();
      } else {
        alert("Erro ao remover produto.");
      }
    } catch (error) {
      console.error("Erro ao remover produto:", error);
    }
  };

  // Prepara um produto para edição
  const handleEditProduct = (product) => {
    setNewProduct(product);
    setEditingProduct(product);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <div className="p-4">
      <h2 className="text-xl font-bold">Painel Administrativo</h2>

      {/* Formulário de Produto */}
      <div className="mt-4 p-4 border rounded shadow">
        <h3 className="font-bold">{editingProduct ? "Editar Produto" : "Adicionar Produto"}</h3>
        <div>
          <label>Nome:</label>
          <input
            type="text"
            value={newProduct.name}
            onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
            className="block border rounded p-2 w-full"
          />
        </div>
        <div>
          <label>Preço:</label>
          <input
            type="text"
            value={newProduct.price}
            onChange={(e) => setNewProduct({ ...newProduct, price: e.target.value })}
            className="block border rounded p-2 w-full"
          />
        </div>
        <div>
          <label>Categoria:</label>
          <input
            type="text"
            value={newProduct.category}
            onChange={(e) => setNewProduct({ ...newProduct, category: e.target.value })}
            className="block border rounded p-2 w-full"
          />
        </div>
        <div>
          <label>Ativo:</label>
          <select
            value={newProduct.active}
            onChange={(e) => setNewProduct({ ...newProduct, active: parseInt(e.target.value) })}
            className="block border rounded p-2 w-full"
          >
            <option value={1}>Sim</option>
            <option value={0}>Não</option>
          </select>
        </div>
        <button
          onClick={handleSaveProduct}
          className="bg-green-500 text-white px-4 py-2 rounded mt-4"
        >
          {editingProduct ? "Atualizar Produto" : "Adicionar Produto"}
        </button>
      </div>

      {/* Lista de Produtos */}
      <div className="mt-4">
        <h3 className="font-bold">Lista de Produtos</h3>
        <ul>
          {products.map((product) => (
            <li key={product.id} className="p-4 border rounded shadow mt-2">
              <div className="flex justify-between">
                <div>
                  <p><b>Nome:</b> {product.name}</p>
                  <p><b>Preço:</b> {product.price}</p>
                  <p><b>Categoria:</b> {product.category}</p>
                  <p><b>Ativo:</b> {product.active ? "Sim" : "Não"}</p>
                </div>
                <div>
                  <button
                    onClick={() => handleEditProduct(product)}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                  >
                    Editar
                  </button>
                  <button
                    onClick={() => handleDeleteProduct(product.id)}
                    className="bg-red-500 text-white px-4 py-2 rounded ml-2"
                  >
                    Excluir
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdminPanel;
