// ThankYouPage.jsx

import React from "react";

const ThankYouPage = () => {
  return (
    <div className="p-4 flex flex-col justify-center items-center">
      <h2 className="text-3xl font-bold text-gray-800 mb-4">Obrigado pela sua compra!</h2>
      <p className="text-lg text-gray-600 mb-4">
        Seu pedido foi enviado com sucesso. Em breve, você receberá a confirmação!
      </p>
      <button
        onClick={() => window.location.href = "/"} // Redireciona para a Home
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
      >
        Voltar para a Home
      </button>
    </div>
  );
};

export default ThankYouPage;
