import React, { useState, useEffect } from "react";
import { useCart } from "../contexts/CartContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Cart = () => {
  const { cartItems, removeFromCart, updateQuantity } = useCart();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("");  // Forma de pagamento
  const [address, setAddress] = useState("");  // Endereço para entrega
  const [deliveryOption, setDeliveryOption] = useState("entrega");  // Entrega ou retirada
  const [cashChange, setCashChange] = useState("");  // Troco
  const [showStatusModal, setShowStatusModal] = useState(false);

  // Função para calcular o total
  const total = cartItems.reduce(
    (acc, item) => acc + (item.preco || 0) * item.quantity,
    0
  );

  // Função para buscar os produtos da API externa
  const fetchProducts = async () => {
    try {
      const response = await axios.get("http://mimimiracoes.com.br/getProducts.php");
      setProducts(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao buscar produtos:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  if (loading) {
    return <p>Carregando...</p>;
  }

  const handleConfirmOrder = () => {
    if (cartItems.length === 0) {
      alert("Seu carrinho está vazio. Adicione produtos antes de confirmar!");
      return;
    }
    if (!paymentMethod) {
      alert("Por favor, selecione a forma de pagamento!");
      return;
    }
    if (deliveryOption === "entrega" && !address) {
      alert("Por favor, preencha o endereço para entrega!");
      return;
    }
    setShowStatusModal(true);
  };

  const sendOrderToWhatsApp = () => {
    const orderDetails = cartItems
      .map(
        (item) =>
          `${item.quantity}x ${item.nome} - ${
            item.variation?.weight || "N/A"
          } ${item.variation?.flavor || "N/A"} - R$ ${Number(item.preco).toFixed(2)}`
      )
      .join("\n");

    const message = `
      *Novo Pedido*%0A
      *Itens:%0A* ${orderDetails}%0A%0A
      *Total:* R$ ${total.toFixed(2)}%0A
      *Forma de pagamento:* ${paymentMethod}
      ${
        paymentMethod === "dinheiro" && cashChange
          ? `%0ATroco para: R$ ${cashChange}`
          : ""
      }
      %0A*Entrega:* ${
        deliveryOption === "entrega"
          ? `Para o endereço: ${address}`
          : "Retirada na loja"
      }
    `;

    const whatsappNumber = "5541999999999";
    window.open(
      `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`,
      "_blank"
    );

    setShowStatusModal(false);
    navigate("/thank-you");
  };

  const handleQuantityChange = (id, newQuantity) => {
    if (newQuantity < 1) {
      alert("A quantidade deve ser maior que zero!");
      return;
    }
    updateQuantity(id, newQuantity);
  };
  

   


  return (
    <div className="p-4">
      <h2 className="text-xl font-bold text-gray-800 mb-4">Carrinho</h2>

      {/* Verifica se o carrinho tem itens */}
      {cartItems.length > 0 ? (
        cartItems.map((item, index) => (
          <div key={index} className="cart-item flex items-center space-x-4 border-b py-4">
            <img
              src={item.imagem || "/images/placeholder.png"}
              alt={item.nome}
              className="w-16 h-16 object-contain"
            />
            <div className="flex-1">
              <h3 className="font-semibold">{item.nome}</h3>
              <p className="text-green-500 font-bold">R$ {Number(item.preco) && !isNaN(Number(item.preco)) ? Number(item.preco).toFixed(2) : "0.00"}</p>
              {item.variation && (
                <p className="text-sm text-gray-500">
                  {`Variação: ${item.variation.flavor} - ${item.variation.weight}`}
                </p>
              )}
              <p className="text-green-500 font-bold">
                R$ {Number(item.preco) && !isNaN(Number(item.preco)) ? Number(item.preco).toFixed(2) : "0.00"}
              </p>

              <div className="flex items-center mt-2">
                <button
                  onClick={() =>
                    updateQuantity(item.id, item.quantity - 1, item.variation)
                  }
                  className="bg-gray-200 px-2 py-1 rounded shadow hover:bg-gray-300"
                >
                  -
                </button>
                <input
                  type="number"
                  value={item.quantity}
                  onChange={(e) =>
                    updateQuantity(
                      item.id,
                      parseInt(e.target.value) || 1,
                      item.variation
                    )
                  }
                  className="w-12 text-center border rounded mx-2"
                  min="1"
                />
                <button
                  onClick={() =>
                    updateQuantity(item.id, item.quantity + 1, item.variation)
                  }
                  className="bg-gray-200 px-2 py-1 rounded shadow hover:bg-gray-300"
                >
                  +
                </button>
              </div>
            </div>
            <button
              onClick={() => removeFromCart(item.id, item.variation)}
              className="bg-red-500 text-white px-4 py-2 rounded shadow hover:bg-red-600"
            >
              Remover
            </button>
          </div>
        ))
      ) : (
        <p className="text-gray-500">Seu carrinho está vazio.</p>
      )}

      <div className="mt-4">
        <p className="text-lg font-bold">
          Total: R$ {total && !isNaN(total) ? total.toFixed(2) : "0.00"}
        </p>
      </div>

      <div className="mt-4">
        {/* Forma de pagamento */}
        <label className="block mb-2 text-sm font-medium text-gray-700">Forma de Pagamento</label>
        <select
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
          className="w-full p-2 border rounded-md"
        >
          <option value="">Selecione...</option>
          <option value="dinheiro">Dinheiro</option>
          <option value="pix">Pix</option>
          <option value="cartaoCredito">Cartão de Crédito</option>
          <option value="cartaoDebito">Cartão de Débito</option>
        </select>
        {paymentMethod === "dinheiro" && (
          <div className="mt-2">
            <label className="block mb-2 text-sm font-medium text-gray-700">Troco para</label>
            <input
              type="number"
              value={cashChange}
              onChange={(e) => setCashChange(e.target.value)}
              placeholder="Insira o valor"
              className="w-full p-2 border rounded-md"
            />
          </div>
        )}
      </div>

      <div className="mt-4">
        {/* Forma de entrega */}
        <label className="block mb-2 text-sm font-medium text-gray-700">Forma de Entrega</label>
        <select
          value={deliveryOption}
          onChange={(e) => setDeliveryOption(e.target.value)}
          className="w-full p-2 border rounded-md"
        >
          <option value="entrega">Entrega</option>
          <option value="retirada">Retirada</option>
        </select>
        {deliveryOption === "entrega" && (
          <div className="mt-2">
            <label className="block mb-2 text-sm font-medium text-gray-700">Endereço de Entrega</label>
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Digite o endereço para entrega"
              className="w-full p-2 border rounded-md"
            />
          </div>
        )}
        {deliveryOption === "retirada" && (
          <p className="mt-2 text-sm text-gray-500">
            Endereço para retirada: Rua da Loja, 123 - Centro
          </p>
        )}
      </div>

      <div className="mt-6">
        <button
          onClick={handleConfirmOrder}
          className="w-full bg-green-600 text-white py-2 rounded-md hover:bg-green-700"
        >
          Confirmar Pedido
        </button>
      </div>

      {/* Modal de Status do Pedido */}
      {showStatusModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm mx-auto">
            <h3 className="text-lg font-semibold mb-4">Pedido Confirmado</h3>
            <p className="text-sm mb-4">Seu pedido foi enviado. Você será redirecionado para o WhatsApp para finalizar.</p>
            <button
              onClick={sendOrderToWhatsApp}
              className="bg-blue-500 text-white py-2 px-4 rounded-md"
            >
              Enviar para WhatsApp
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
